import {
  Add,
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  InfoOutlined,
  Remove,
  WarningAmber,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// import InputAdornment from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers-v5/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers-v5/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-v5/LocalizationProvider";
import PhoneTextField from "components/PhoneTextField";
import getSymbolFromCurrency from "currency-symbol-map";
import { queryClient } from "index";
import moment from "moment";
import { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  GlobalState,
  setLoader,
  snackbar,
  titleCase,
} from "utils";
import { BOLT_URL, PAYMENTS_URL } from "utils/constants";
import PaymentDialog from "views/Charger/Users/PaymentDialog";
import SetAvailability from "../SetAvailability";
// import ChargerHealth from "../Overview/ChargerHealth";

const inputObj: any = {
  maintainerName: "",
  maintainerPhone: "",

  // Station info
  address: "",
  city: "",
  pincode: "",
  district: "",
  state: "",
  country: "",
  latitude: "",
  longitude: "",

  // Payment details
  ownerCostPerkWh: "",
  costPerkWh: "",
  platformFee: "",
  platformFeeDiscount: "",
  settlementCycle: "",
  paymentMethodId: "",
  
  // Warranty details
  warrantyInitialsedDate: "",
  warrantyExpiryDate: "",

  overVoltage: 210,
  overVoltageTime: 0,
  underVoltage: 140,
  underVoltageTime: 0,
  overCurrent: 0,
  overCurrentTime: 0,
  overTemperature: 50,
  overTemperatureTime: 0,
  underTemperature: "",
  underTemperatureTime: 0,
  autoCutOff: "",
  autoCutOffTime: 0,
  currentFactor: "",
  voltageFactor: "",
  activePowerFactor: "",
  energyFactor: "",
  powerOutput: 0,
};

const EditChargerDialog = ({
  open,
  handleClose,
  count,
  data,
  configData,
  closeDrawer,
}: any) => {
  let { masterView, user } = useSelector((state: GlobalState) => state.global);
  const isCRMAccount = [
    "pratik.var@bolt.earth",
    "aswathy.tp@bolt.earth",
    "athul.sivadas@bolt.earth",
  ].includes(user.email);
  const userId = data?.charger?.registrantId;
  const currency = data?.paymentDetails?.currency;

  const [step, setStep] = useState<number>(0);
  const steps = ["Station Info", "Availability", "Specification", "Finish"];

  const [usageType, setUsageType] = useState("");
  const [twentyFourSeven, setTwentyFourSeven] = useState(false);
  const [days, setDays] = useState<any>([]);

  const chargerProtocol = data?.chargerType?.communicationProtocol;

  const chargerSpecificationUrl = `${BOLT_URL}/charger/${data?.charger?.chargerId}/config`;
  const { data: chargerConfigData } = useQuery(
    ["getChargerSpecification", data?.charger?.chargerId],
    () =>
      authorizedFetch(chargerSpecificationUrl, {
        headers: {
          master: masterView,
        },
      }),
    {
      enabled: !!data?.charger?.chargerId,
    }
  );

  const [input, setInput] = useState({ ...inputObj });

  const [paymentDialog, setPaymentDialog] = useState<any>({
    open: false,
    data: null,
  });

  const {
    // uid,
    chargerName,
    maintainerName,
    maintainerPhone,

    // stationName,
    address,
    city,
    pincode,
    district,
    state,
    latitude,
    longitude,
    country,

    ownerCostPerkWh,
    costPerkWh,
    platformFee,
    platformFeeDiscount,
    settlementCycle,
    paymentMethodId,

    // warrantyInitialsedDate,
    warrantyExpiryDate,

    overVoltage,
    overVoltageTime,
    underVoltage,
    underVoltageTime,
    overCurrent,
    overCurrentTime,
    overTemperature,
    overTemperatureTime,
    underTemperature,
    autoCutOff,
    autoCutOffTime,
    underTemperatureTime,
    currentFactor,
    voltageFactor,
    activePowerFactor,
    energyFactor,
    powerOutput,
  } = input;

  const uid = data.charger.chargerId;

  const isPaid = [
    "PUBLIC_PAID",
    "HIDDEN_PUBLIC_PAID",
    "LIMITED_PUBLIC_PAID",
    "PRIVATE_SHARABLE_PAID",
  ].includes(usageType);

  const { isLoading: paymentMethodsLoading, data: paymentMethodsData } =
    useQuery(
      ["getPaymentDetails", userId],
      () =>
        authorizedFetch(
          `${PAYMENTS_URL}/v1/payments/paymentmeta/default/getAll?userId=${userId}`,
        ),
      {
        enabled: !!userId && isPaid,
      },
    );

  useEffect(() => {
    if (!open) return;
    setStep(count);
  }, [open, count]);

  useEffect(() => {
    if (!open) return;
    if (data && chargerConfigData) {
      setUsageType(data.charger.usageType);

      const chargerConfigParams =
        chargerConfigData?.data?.specification?.chargerConfigParams;

      setInput({
        uid: data.charger.chargerId,
        name: data.charger.chargerName,
        chargerName: data.charger.chargerName,
        maintainerName: data.incharge.name,
        maintainerPhone: data.incharge.phoneNumber,
        // stationName: data.station.stationName,
        address: data.station.address,
        city: data.station.city,
        pincode: data.station.postalCode,
        district: data.station.district,
        state: data.station.state,
        country: data.station.country,
        latitude: data.station.location.latitude,
        longitude: data.station.location.longitude,

        ownerCostPerkWh: String(data.paymentDetails.ownerCostPerkWh),
        costPerkWh: String(data.paymentDetails.costPerkWh),
        platformFee: String(data.paymentDetails.platformFee),
        platformFeeDiscount: String(data.paymentDetails.platformFeeDiscount),
        settlementCycle: data.paymentDetails.settlementCycle || "WEEKLY",
        paymentMethodId: data.paymentDetails.paymentMethodId || "NA",

        // warrantyInitialsedDate: data.warranty?.initialisedDate || "",
        warrantyExpiryDate: data.warranty?.warrantyExpiry || "",

        powerOutput: chargerConfigData?.data?.specification?.powerOutput?.value,
        overVoltage:
          chargerConfigData?.data?.specification?.chargerConfigParams
            ?.overVoltage,
        overVoltageTime: chargerConfigParams?.overVoltageTime?.value,
        underVoltage: chargerConfigParams?.underVoltage,
        underVoltageTime: chargerConfigParams?.underVoltageTime?.value,
        overCurrent: chargerConfigParams?.overCurrent,
        overCurrentTime: chargerConfigParams?.overCurrentTime?.value,
        overTemperature: chargerConfigParams?.overTemperature,
        overTemperatureTime: chargerConfigParams?.overTemperatureTime?.value,
        underTemperature: chargerConfigParams?.underTemperature,
        underTemperatureTime: chargerConfigParams?.underTemperatureTime?.value,
        autoCutOff: chargerConfigParams?.autoCutOff?.value,
        autoCutOffTime: chargerConfigParams?.autoCutOffTime?.value,
        currentFactor: chargerConfigParams?.currentFactor,
        voltageFactor: chargerConfigParams?.voltageFactor,
        activePowerFactor: chargerConfigParams?.activePowerFactor,
        energyFactor: data.charger.energyFactor,
      });
      setTwentyFourSeven(data.availability.twentyFourSeven);
      setDays(data.availability.days);
    } else {
      setInput({ ...inputObj });
      setTwentyFourSeven(false);
      setDays([]);
    }
  }, [data, open, chargerConfigData]);

  function formatDays() {
    let data = [...days];
    return data.map((el: any) => ({
      day: el.day,
      fromTime: moment(el.fromTime).format("HH:mm"),
      toTime: moment(el.toTime).format("HH:mm"),
    }));
  }

  function handleNext() {
    if (step === steps.length - 1) {
      onSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isPriceValid(price: any) {
    return currency === "INR"
      ? parseFloat(price) >= 1 && parseFloat(price) <= 30
      : true;
  }

  function isDisabled() {
    if (
      [
        chargerName,

        maintainerName,
        maintainerPhone,
        address,
        city,
        pincode,
        district,
        state,
        latitude,
        longitude,
        country,

        usageType,

        days,
        twentyFourSeven,
      ].includes("")
    )
      return true;

    if (chargerProtocol === "OCPP") {
      if (!(autoCutOffTime > 0 && autoCutOffTime <= 255)) return true;
    } else {
      if (
        [
          String(overVoltage > 209 && overVoltage < 301 ? overVoltage : ""),
          String(
            overVoltageTime > 0 && overVoltageTime <= 255 ? overVoltageTime : ""
          ),
          String(underVoltage > 139 && underVoltage < 221 ? underVoltage : ""),
          String(
            underVoltageTime > 0 && underVoltageTime <= 255
              ? underVoltageTime
              : ""
          ),
          String(overCurrent > 4 && overCurrent < 18 ? overCurrent : ""),
          String(
            overCurrentTime > 0 && overCurrentTime <= 255 ? overCurrentTime : ""
          ),
          String(
            overTemperature > 49 && overTemperature < 91 ? overTemperature : ""
          ),
          String(
            overTemperatureTime > 0 && overTemperatureTime <= 255
              ? overTemperatureTime
              : ""
          ),
          String(
            Number(underTemperature) > -31 && Number(underTemperature) < 51
              ? underTemperature
              : ""
          ),
          String(
            underTemperatureTime > 0 && underTemperatureTime <= 255
              ? underTemperatureTime
              : ""
          ),
          String(
            Number(autoCutOff) > 0 && Number(autoCutOff) < 5.1 ? autoCutOff : ""
          ),
          String(
            autoCutOffTime > 0 && autoCutOffTime <= 255 ? autoCutOffTime : ""
          ),

          Number(currentFactor) > 0 && Number(currentFactor) < 2.1
            ? currentFactor
            : "",
          Number(voltageFactor) > 0 && Number(voltageFactor) < 2.1
            ? voltageFactor
            : "",
          Number(activePowerFactor) > 0 && Number(activePowerFactor) < 2.1
            ? activePowerFactor
            : "",
          Number(energyFactor) > 0 && Number(energyFactor) < 2.1
            ? energyFactor
            : "",
        ].includes("")
      )
        return true;
    }
    if (isPaid) {
      if (
        [costPerkWh, ownerCostPerkWh].some((el) => !isPriceValid(el)) ||
        parseFloat(ownerCostPerkWh) >= parseFloat(costPerkWh) ||
        [platformFee, platformFeeDiscount].includes("") ||
        parseFloat(platformFee) < 0 ||
        parseFloat(platformFee) > 50 ||
        parseFloat(platformFeeDiscount) < 0 ||
        parseFloat(platformFeeDiscount) > 100 ||
        !paymentMethodId ||
        paymentMethodId === "NA"
      )
        return true;
    }

    return false;
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          ![
            chargerName,
            maintainerName,
            maintainerPhone,
            address,
            city,
            pincode,
            district,
            state,
            latitude,
            longitude,
            country,
            usageType,
            ownerCostPerkWh,
            costPerkWh,
          ].includes("") &&
          (isPaid ? !!paymentMethodId && paymentMethodId !== "NA" : true)
        );
      case 1:
        return ![days, twentyFourSeven].includes("");
      case 2:
        return chargerProtocol === "OCPP"
          ? ![days, twentyFourSeven].includes("") &&
              autoCutOffTime > 0 &&
              autoCutOffTime <= 255
          : ![
              days,
              twentyFourSeven,
              Number(currentFactor) > 0 && Number(currentFactor) < 2.1
                ? currentFactor
                : "",
              Number(voltageFactor) > 0 && Number(voltageFactor) < 2.1
                ? voltageFactor
                : "",
              Number(activePowerFactor) > 0 && Number(activePowerFactor) < 2.1
                ? activePowerFactor
                : "",
              Number(energyFactor) > 0 && Number(energyFactor) < 2.1
                ? energyFactor
                : "",
            ].includes("") &&
              overVoltage > 209 &&
              overVoltage < 301 &&
              overVoltageTime > 0 &&
              overVoltageTime <= 255 &&
              underVoltage > 139 &&
              underVoltage < 221 &&
              underVoltageTime > 0 &&
              underVoltageTime <= 255 &&
              overCurrent > 4 &&
              overCurrent < 18 &&
              overCurrentTime > 0 &&
              overCurrentTime <= 255 &&
              overTemperature > 49 &&
              overTemperature < 91 &&
              overTemperatureTime > 0 &&
              overTemperatureTime <= 255 &&
              Number(underTemperature) > -31 &&
              Number(underTemperature) < 51 &&
              underTemperatureTime > 0 &&
              underTemperatureTime <= 255 &&
              Number(autoCutOff) > 0 &&
              Number(autoCutOff) < 5.1 &&
              autoCutOffTime > 0 &&
              autoCutOffTime <= 255;
      default:
        break;
    }
  }

  function returnTime(day: number) {
    return twentyFourSeven
      ? "All Day"
      : days.some((el: any) => el.day === day) === false
        ? "Unavailable"
        : days
            .filter((el: any) => el.day === day)
            .map((el: any) => (
              <div>
                {moment(el.fromTime, ["ddd MMM DD YYYY HH:mm:ss"]).format(
                  "h:mm A",
                ) +
                  "-" +
                  moment(el.toTime, ["ddd MMM DD YYYY HH:mm:ss"]).format(
                    "h:mm A",
                  )}
              </div>
            ));
  }

  function onSave() {
    const chargerConfigParams =
      chargerConfigData?.data?.specification?.chargerConfigParams;

    const configObj: any = {
      overVoltage: Number(overVoltage),
      overVoltageTime: Number(overVoltageTime),
      underVoltage: Number(underVoltage),
      underVoltageTime: Number(underVoltageTime),
      overCurrent: Number(overCurrent),
      overCurrentTime: Number(overCurrentTime),
      overTemperature: Number(overTemperature),
      overTemperatureTime: Number(overTemperatureTime),
      underTemperature: Number(underTemperature),
      underTemperatureTime: Number(underTemperatureTime),
      autoCutOff: Number(autoCutOff),
      autoCutOffTime: Number(autoCutOffTime),
      currentFactor: Number(currentFactor),
      voltageFactor: Number(voltageFactor),
      activePowerFactor: Number(activePowerFactor),
      // energyFactor: Number(energyFactor),
    };

    let hasConfigChanged = false;

    if (
      overVoltage !== chargerConfigParams?.overVoltage ||
      overVoltageTime !== chargerConfigParams?.overVoltageTime?.value ||
      underVoltage !== chargerConfigParams?.underVoltage ||
      underVoltageTime !== chargerConfigParams?.underVoltageTime?.value ||
      overCurrent !== chargerConfigParams?.overCurrent ||
      overCurrentTime !== chargerConfigParams?.overCurrentTime?.value ||
      overTemperature !== chargerConfigParams?.overTemperature ||
      overTemperatureTime !== chargerConfigParams?.overTemperatureTime?.value ||
      underTemperature !== chargerConfigParams?.underTemperature ||
      underTemperatureTime !==
        chargerConfigParams?.underTemperatureTime?.value ||
      autoCutOff !== chargerConfigParams?.autoCutOff?.value ||
      autoCutOffTime !== chargerConfigParams?.autoCutOffTime?.value ||
      currentFactor !== chargerConfigParams?.currentFactor ||
      voltageFactor !== chargerConfigParams?.voltageFactor ||
      activePowerFactor !== chargerConfigParams?.activePowerFactor
      // || energyFactor !== data.charger.energyFactor
    )
      hasConfigChanged = true;

    setLoader(true);
    authorizedFetch(`${BOLT_URL}/v2/charger/${uid}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        stage: "prod",
      },
      body: {
        availability: {
          days: formatDays(),
          twentyFourSeven: twentyFourSeven,
        },
        charger: {
          chargerName: chargerName,
          usageType: usageType,
        },
        station: {
          address: address,
          city: city,
          postalCode: pincode,
          district: district,
          state: state,
          country: country,
          location: {
            latitude: latitude,
            longitude: longitude,
          },
        },
        incharge: {
          name: maintainerName,
          phoneNumber: maintainerPhone,
        },
        paymentDetails: {
          tariffMode: "KWH",
          ownerCostPerkWh: parseFloat(ownerCostPerkWh),
          costPerkWh: parseFloat(costPerkWh),
          baseAmount: 0,
          chargePerHour: 0,
          ...(settlementCycle ? { settlementCycle: settlementCycle } : {}),
          paymentMethodId: isPaid ? paymentMethodId : "NA",
          platformFee: parseFloat(platformFee),
          platformFeeDiscount: parseFloat(platformFeeDiscount),
        },
        ...(typeof warrantyExpiryDate === "object"
          ? {
              warranty: {
                warrantyExpiry: warrantyExpiryDate.toISOString(),
              },
            }
          : {}),
        specification: {
          powerOutput: powerOutput,
        },
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          ["getCharger", "getChargers", "getAllStats"].forEach((el) => {
            queryClient.resetQueries(el);
          });
          snackbar.success(`Charger updated`);
          handleClose();
        } else {
          snackbar.error(
            `Error${res.message ? ": " + res.message : " updating charger"}`,
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error(`Error updating charger`);
      });

    if (hasConfigChanged && chargerProtocol === "MQTT") {
      authorizedFetch(`${BOLT_URL}/charger/${uid}/config`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: configObj,
      }).then((res) => {
        if (res.status === 200) {
          snackbar.success(`Charger config updated`);
        } else {
          snackbar.error(
            `Error updating charger config${
              res.message ? `: ${res.message}` : ""
            }`
          );
        }
      });
    }
  }

  const regex = {
    twoDigit: /^[0-9]{0,2}$/,
    threeDigit: /^[0-9]{0,3}$/,
    negativePositive: /^[-]?\d*$/,
    twoDecimal: /^((?!0)\d{1}|0|[.]?\d{0})($|\.$|\.\d{1,2}$)/,
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { maxWidth: 800, width: 1 } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Edit Charger
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="text.secondary">
            {data.charger.chargerId}
          </Typography>
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {/* {step === 0 && (
          <Box
            sx={{
              maxWidth: 280,
              mx: "auto",
              py: 2,
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: 3,
            }}
          >
            <Box>
              <Typography className="label">UID</Typography>
              <TextField
                fullWidth
                disabled
                size="small"
                placeholder="UID"
                value={uid}
                onChange={(e: any) => {
                  setInput({ ...input, uid: e.target.value });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Name"
                value={name}
                onChange={(e: any) => {
                  setInput({ ...input, name: e.target.value });
                }}
              />
            </Box>
          </Box>
        )} */}
        {step === 0 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: { sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box
              sx={{
                gridColumn: { sm: "span 2" },
              }}
            >
              <Typography className="label">Usage Type</Typography>
              <RadioGroup
                row
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr 1fr",
                    sm: "repeat(3, 1fr)",
                  },
                }}
                value={usageType}
                onChange={(e: any) => setUsageType(e.target.value)}
              >
                {[
                  "PUBLIC_PAID",
                  "PUBLIC_FREE",
                  "LIMITED_PUBLIC_PAID",
                  "LIMITED_PUBLIC_FREE",
                  "HIDDEN_PUBLIC_PAID",
                  "HIDDEN_PUBLIC_FREE",
                  "PRIVATE_SHARABLE_PAID",
                  "PRIVATE_SHARABLE_FREE",
                  ...(!data?.isRupeeCharger ? ["PRIVATE"] : []),
                  // "RESTRICTED_PAID",
                  // "RESTRICTED_FREE",
                ].map((el, i) => (
                  <FormControlLabel
                    key={i}
                    value={el}
                    control={<Radio />}
                    label={el.split("_").map(titleCase).join(" ")}
                  />
                ))}
              </RadioGroup>
            </Box>
            <Box gridColumn={{ sm: "span 2" }}>
              <Typography className="label">Charger Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Charger Name"
                value={chargerName}
                onChange={(e: any) => {
                  setInput({
                    ...input,
                    chargerName: e.target.value,
                  });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Maintainer Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Maintainer Name"
                value={maintainerName}
                onChange={(e: any) => {
                  setInput({ ...input, maintainerName: e.target.value });
                }}
              />
            </Box>
            <Box>
              <Typography className="label">Maintainer Phone</Typography>
              <PhoneInput
                placeholder="Maintainer Phone"
                value={maintainerPhone}
                onChange={(value) =>
                  setInput({ ...input, maintainerPhone: value || "" })
                }
                defaultCountry="IN"
                international
                limitMaxLength
                inputComponent={PhoneTextField}
                error={
                  !!maintainerPhone && !isValidPhoneNumber(maintainerPhone)
                }
              />
            </Box>

            <Box sx={{ gridColumn: { sm: "span 2" } }}>
              <Typography className="label">Station Address</Typography>
              <Box
                sx={{
                  maxWidth: 560,
                  mx: "auto",
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                  gap: 2,
                  mt: 2,
                }}
              >
                <Box sx={{ gridColumn: { sm: "span 2" } }}>
                  <Typography fontSize={14} mb={1.5}>
                    Plot No., Street Name, Landmark
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Plot No., Street Name, Landmark"
                    value={address}
                    onChange={(e: any) => {
                      setInput({
                        ...input,
                        address: e.target.value,
                      });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    City
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="City"
                    value={city}
                    onChange={(e: any) => {
                      if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                        setInput({
                          ...input,
                          city: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    Pincode
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Pincode"
                    value={pincode}
                    onChange={(e: any) => {
                      if (/^[0-9]{0,6}$/.test(e.target.value))
                        setInput({
                          ...input,
                          pincode: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    District
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="District"
                    value={district}
                    onChange={(e: any) => {
                      if (/^[a-zA-Z\s]{0,20}$/.test(e.target.value))
                        setInput({
                          ...input,
                          district: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    State
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="State"
                    value={state}
                    onChange={(e: any) => {
                      if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                        setInput({
                          ...input,
                          state: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    Latitude
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Latitude"
                    value={latitude}
                    onChange={(e: any) => {
                      if (/^[-]?[0-9.]{0,11}$/.test(e.target.value))
                        setInput({
                          ...input,
                          latitude: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={14} mb={1}>
                    Longitude
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Longitude"
                    value={longitude}
                    onChange={(e: any) => {
                      if (/^[-]?[0-9.]{0,11}$/.test(e.target.value))
                        setInput({
                          ...input,
                          longitude: e.target.value,
                        });
                    }}
                  />
                </Box>
                <Box sx={{ gridColumn: { sm: "span 2" } }}>
                  <Typography fontSize={14} mb={1}>
                    Country
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Country"
                    value={country}
                    onChange={(e: any) => {
                      if (/^[a-zA-Z]{0,20}$/.test(e.target.value))
                        setInput({
                          ...input,
                          country: e.target.value,
                        });
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {isPaid && (
              <Box sx={{ gridColumn: "1/-1" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 3,
                  }}
                >
                  <Box sx={{ mt: 1, gridColumn: "1/-1" }}>
                    <Typography variant="overline">Payment Details</Typography>
                    <Divider />
                  </Box>
                  {[
                    {
                      key: "ownerCostPerkWh",
                      label: "Energy Tariff",
                    },
                    {
                      key: "costPerkWh",
                      label: "Cost Per Unit (kWh)",
                    },
                  ].map(({ key, label }, i) => (
                    <Box key={i}>
                      <Typography className="label">{label}</Typography>
                      <PriceInput
                        {...{
                          objKey: key,
                          label,
                          input,
                          setInput,
                          isPriceValid,
                          currency,
                        }}
                      />
                    </Box>
                  ))}
                  <Alert severity="info" sx={{ gridColumn: "1/-1", mt: -1.5 }}>
                    <Typography variant="body2">
                      <b>Cost Per Unit</b> should be greater than the{" "}
                      <b>Energy Tariff</b>
                    </Typography>
                  </Alert>
                  <Box>
                    <Typography className="label">Platform Fee</Typography>
                    <PriceInput
                      {...{
                        objKey: "platformFee",
                        label: "Platform Fee",
                        input,
                        setInput,
                        isPriceValid: (price: any) =>
                          parseFloat(price) >= 0 && parseFloat(price) <= 50,
                        currency,
                        min: 0,
                        max: 50,
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      Platform Fee Discount
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      placeholder="Platform Fee Discount"
                      value={platformFeeDiscount}
                      onChange={(e: any) => {
                        if (
                          e.target.value === "" ||
                          /^[0-9.]{0,5}$/.test(e.target.value)
                        )
                          setInput({
                            ...input,
                            platformFeeDiscount: e.target.value,
                          });
                      }}
                      error={
                        platformFeeDiscount !== "" &&
                        (parseFloat(platformFeeDiscount) > 100 ||
                          parseFloat(platformFeeDiscount) < 0)
                      }
                      helperText={
                        currency === "INR"
                          ? "Enter a value between 0 and 100"
                          : undefined
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ mr: 1 }}>
                            %
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <FormControl>
                    <Typography className="label">
                      Settlement Frequency
                    </Typography>
                    <RadioGroup
                      value={settlementCycle}
                      onChange={(e) =>
                        setInput({ ...input, settlementCycle: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="BIWEEKLY"
                        control={<Radio size="small" />}
                        label="Biweekly"
                      />
                      <FormControlLabel
                        value="WEEKLY"
                        control={<Radio size="small" />}
                        label="Weekly"
                      />
                      <FormControlLabel
                        value="MONTHLY"
                        control={<Radio size="small" />}
                        label="Monthly"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Box>
                    <Typography className="label">Payment Method</Typography>
                    <Box>
                      {paymentMethodsLoading ? (
                        <Typography color="text.disabled">
                          Loading...
                        </Typography>
                      ) : (
                        <>
                          {paymentMethodsData?.data?.constructor === Array &&
                          paymentMethodsData.data.length > 0 ? (
                            <RadioGroup
                              value={paymentMethodId}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  paymentMethodId: e.target.value,
                                })
                              }
                            >
                              {paymentMethodsData.data.map(
                                (el: any, i: any) => (
                                  <FormControlLabel
                                    key={i}
                                    value={el._id}
                                    control={<Radio size="small" />}
                                    label={`${
                                      el.bankDetails.bankName
                                    } (•••• ${el.bankDetails.bankAccount.slice(
                                      -4,
                                    )})`}
                                  />
                                ),
                              )}
                            </RadioGroup>
                          ) : (
                            <Typography color="text.disabled">
                              No payment methods found
                            </Typography>
                          )}
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ mt: 1.5, textTransform: "none" }}
                            onClick={() => {
                              if (!data.owner)
                                snackbar.error("Error fetching user details");
                              else
                                setPaymentDialog({
                                  open: true,
                                  data: {
                                    _id: userId,
                                    ...data.owner,
                                  },
                                });
                            }}
                          >
                            View/Edit Payment Methods
                          </Button>
                        </>
                      )}
                    </Box>
                    <PaymentDialog
                      open={paymentDialog.open}
                      handleClose={() =>
                        setPaymentDialog({ ...paymentDialog, open: false })
                      }
                      data={paymentDialog.data}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {!!data.warranty?.initialisedDate && isCRMAccount && (
              <Box
                sx={{
                  gridColumn: "1/-1",
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 1fr" },
                  gap: 3,
                }}
              >
                <Box sx={{ mt: 1, gridColumn: "1/-1" }}>
                  <Typography variant="overline">Warranty Details</Typography>
                  <Divider />
                </Box>
                <Box>
                  <Typography className="label">Initialised On</Typography>
                  <TextField
                    // sx={{ pointerEvents: "none" }}
                    fullWidth
                    size="small"
                    placeholder="Initialised On"
                    value={moment(data.warranty?.initialisedDate).format(
                      "DD/MM/YYYY",
                    )}
                    disabled
                  />
                </Box>
                <Box>
                  <Typography className="label">Expires On</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      value={warrantyExpiryDate}
                      onChange={(date) => {
                        // Only change the date
                        const oldDate = moment(data.warranty?.warrantyExpiry);
                        date.set({
                          hour: oldDate.hour(),
                          minute: oldDate.minute(),
                          second: oldDate.second(),
                          millisecond: oldDate.millisecond(),
                        });
                        setInput({ ...input, warrantyExpiryDate: date });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                      inputFormat="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            )}
          </Box>
        )}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
            }}
          >
            <SetAvailability
              {...{
                open,
                days,
                setDays,
                twentyFourSeven,
                setTwentyFourSeven,
              }}
            />
          </Box>
        )}
        {step === 2 && (
          <Box
            sx={{
              maxWidth: chargerProtocol === "OCPP" ? 350 : 560,
              mx: "auto",
              display: "grid",
              gridTemplateColumns:
                chargerProtocol === "OCPP"
                  ? { xs: "1fr", sm: "1fr" }
                  : { sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            {chargerProtocol === "OCPP" ? (
              <>
                <Box mt={2}>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Auto Cutoff</Typography>
                    <Tooltip title="Value should be in the range of 1 to 255 seconds">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Auto Cutoff Time"
                    value={autoCutOffTime}
                    onChange={(e: any) => {
                      if (regex.threeDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          autoCutOffTime: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          seconds
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(autoCutOffTime) > 0 &&
                          Number(autoCutOffTime) <= 255
                      )
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box mt={2}>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Power Output</Typography>
                    <Tooltip title="Value should be in the range of 0 to 16 Amphere">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <Box alignItems="center">
                    <Box
                      sx={{
                        border: "1px solid #00000040",
                        width: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 1,
                        mt: 1,
                      }}
                    >
                      <Typography sx={{ p: 0.5 }}>{powerOutput}A</Typography>
                    </Box>

                    <Slider
                      sx={{ mt: 1.5 }}
                      aria-label="Temperature"
                      defaultValue={16}
                      // getAriaValueText={valuetext}
                      value={powerOutput}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={16}
                      onChange={(e: any) => {
                        setInput({ ...input, powerOutput: e.target.value });
                      }}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box mt={2}>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Over Voltage</Typography>
                    <Tooltip title="Value should be in the range of 210 to 300 volt">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Over Voltage"
                    value={overVoltage}
                    onChange={(e: any) => {
                      if (regex.threeDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          overVoltage: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          V
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(overVoltage) > 209 && Number(overVoltage) < 301
                      )
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box mt={2}>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Over Voltage Time</Typography>
                    <Tooltip title="Value should be in the range of 1 to 255 seconds">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Over Voltage Time"
                    value={overVoltageTime}
                    onChange={(e: any) => {
                      if (regex.threeDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          overVoltageTime: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          seconds
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(overVoltageTime) > 0 &&
                          Number(overVoltageTime) <= 255
                      )
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>

                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Under Voltage</Typography>
                    <Tooltip title="Value should be in the range of 140 to 220 volt">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Under Voltage"
                    value={underVoltage}
                    onChange={(e: any) => {
                      if (regex.threeDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          underVoltage: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          V
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(underVoltage) > 139 && Number(underVoltage) < 221
                      )
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">
                      Under Voltage Time
                    </Typography>
                    <Tooltip title="Value should be in the range of 1 to 255 seconds">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Under Voltage Time"
                    value={underVoltageTime}
                    onChange={(e: any) => {
                      if (regex.threeDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          underVoltageTime: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          seconds
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(underVoltageTime) > 0 &&
                          Number(underVoltageTime) <= 255
                      )
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>

                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Over Current</Typography>
                    <Tooltip title="Value should be in the range of 5 to 17 AMP">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Over Current"
                    value={overCurrent}
                    onChange={(e: any) => {
                      if (regex.twoDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          overCurrent: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          AMP
                        </InputAdornment>
                      ),
                    }}
                    error={!Boolean(overCurrent > 4 && overCurrent < 18)}
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Over Current Time</Typography>
                    <Tooltip title="Value should be in the range of 1 to 255 seconds">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Over Current Time"
                    value={overCurrentTime}
                    onChange={(e: any) => {
                      if (regex.threeDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          overCurrentTime: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          seconds
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(overCurrentTime) > 0 &&
                          Number(overCurrentTime) <= 255
                      )
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>

                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Over Temperature</Typography>
                    <Tooltip title="Value should be in the range of 50 to 90 °C">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Over Temperature"
                    value={overTemperature}
                    onChange={(e: any) => {
                      if (regex.twoDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          overTemperature: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          &#8451;
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(overTemperature > 49 && overTemperature < 91)
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">
                      Over Temperature Time
                    </Typography>
                    <Tooltip title="Value should be in the range of 1 to 255 seconds">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Over Temperature Time"
                    value={overTemperatureTime}
                    onChange={(e: any) => {
                      if (regex.threeDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          overTemperatureTime: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          seconds
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(overTemperatureTime) > 0 &&
                          Number(overTemperatureTime) <= 255
                      )
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>

                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Under Temperature</Typography>
                    <Tooltip title="Value should be in the range of -30 to 50 °C">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Under Temperature"
                    value={underTemperature}
                    onChange={(e: any) => {
                      if (regex.negativePositive.test(e.target.value)) {
                        setInput({
                          ...input,
                          underTemperature: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          &#8451;
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(underTemperature) > -31 &&
                          Number(underTemperature) < 51
                      ) || underTemperature === ""
                    }
                    inputProps={
                      Number(underTemperature) < 0
                        ? { maxLength: 3 }
                        : { maxLength: 2 }
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">
                      Under Temperature Time
                    </Typography>
                    <Tooltip title="Value should be in the range of 1 to 255 seconds">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Under Temperature Time"
                    value={underTemperatureTime}
                    onChange={(e: any) => {
                      if (regex.threeDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          underTemperatureTime: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          seconds
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(underTemperatureTime) > 0 &&
                          Number(underTemperatureTime) <= 255
                      )
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>

                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Auto Cutoff</Typography>
                    <Tooltip title="Value should be in the range of 0.1 to 5 AMP">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Auto Cutoff"
                    value={autoCutOff}
                    onChange={(e: any) => {
                      if (regex.twoDecimal.test(e.target.value)) {
                        setInput({
                          ...input,
                          autoCutOff: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          AMP
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(autoCutOff) > 0 && Number(autoCutOff) < 5.1
                      )
                    }
                    inputProps={{ maxLength: 4 }}
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Auto Cutoff Time</Typography>
                    <Tooltip title="Value should be in the range of 1 to 255 seconds">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Auto Cutoff Time"
                    value={autoCutOffTime}
                    onChange={(e: any) => {
                      if (regex.threeDigit.test(e.target.value)) {
                        setInput({
                          ...input,
                          autoCutOffTime: e.target.value,
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          seconds
                        </InputAdornment>
                      ),
                    }}
                    error={
                      !Boolean(
                        Number(autoCutOffTime) > 0 &&
                          Number(autoCutOffTime) <= 255
                      )
                    }
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>

                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Current Factor</Typography>
                    <Tooltip title="Value should be in the range of 0.1 to 2">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Current Factor"
                    value={currentFactor}
                    onChange={(e: any) => {
                      if (regex.twoDecimal.test(e.target.value)) {
                        setInput({
                          ...input,
                          currentFactor: e.target.value,
                        });
                      }
                    }}
                    error={
                      !Boolean(
                        Number(currentFactor) > 0 && Number(currentFactor) < 2.1
                      )
                    }
                    inputProps={{ maxLength: 4 }}
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Voltage Factor</Typography>
                    <Tooltip title="Value should be in the range of 0.1 to 2">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Voltage Factor"
                    value={voltageFactor}
                    onChange={(e: any) => {
                      if (regex.twoDecimal.test(e.target.value)) {
                        setInput({
                          ...input,
                          voltageFactor: e.target.value,
                        });
                      }
                    }}
                    error={
                      !Boolean(
                        Number(voltageFactor) > 0 && Number(voltageFactor) < 2.1
                      )
                    }
                    inputProps={{ maxLength: 4 }}
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">
                      Active Power Factor
                    </Typography>
                    <Tooltip title="Value should be in the range of 0.1 to 2">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Active Power Factor"
                    value={activePowerFactor}
                    onChange={(e: any) => {
                      if (regex.twoDecimal.test(e.target.value)) {
                        setInput({
                          ...input,
                          activePowerFactor: e.target.value,
                        });
                      }
                    }}
                    error={
                      !Boolean(
                        Number(activePowerFactor) > 0 &&
                          Number(activePowerFactor) < 2.1
                      )
                    }
                    inputProps={{ maxLength: 4 }}
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Energy Factor</Typography>
                    <Tooltip title="Value should be in the range of 0.1 to 2">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Energy Factor"
                    value={energyFactor}
                    onChange={(e: any) => {
                      if (regex.twoDecimal.test(e.target.value)) {
                        setInput({ ...input, energyFactor: e.target.value });
                      }
                    }}
                    error={
                      !Boolean(
                        Number(energyFactor) > 0 && Number(energyFactor) < 2.1
                      )
                    }
                    inputProps={{ maxLength: 4 }}
                    sx={{ mt: 1 }}
                    autoComplete="off"
                  />
                </Box>

                <Box
                  gridColumn={{ sm: "span 2" }}
                  sx={{
                    width: "275px",
                    margin: "0 auto",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography className="label">Power Output</Typography>
                    <Tooltip title="Value should be in the range of 0 to 16 Amphere">
                      <InfoOutlined
                        color="action"
                        fontSize="inherit"
                        sx={{ ml: 0.5, mt: -1.5, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                  <Box alignItems="center">
                    <Box
                      sx={{
                        border: "1px solid #00000040",
                        width: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 1,
                        mt: 1,
                      }}
                    >
                      <Typography sx={{ p: 0.5 }}>{powerOutput}A</Typography>
                    </Box>

                    <Slider
                      sx={{ mt: 1.5 }}
                      aria-label="Temperature"
                      defaultValue={16}
                      // getAriaValueText={valuetext}
                      value={powerOutput}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={16}
                      onChange={(e: any) => {
                        setInput({ ...input, powerOutput: e.target.value });
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )}

        {step === 3 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Station Info", onEdit: () => setStep(0) },
                  { label: "Charger Name", value: chargerName, required: true },
                  { label: "Payment Type", value: usageType, required: true },
                  {
                    label: "Maintainer Name",
                    value: maintainerName,
                    required: true,
                  },
                  {
                    label: "Maintainer Phone",
                    value: maintainerPhone,
                    required: true,
                  },
                  {
                    label: "Plot No., Street Name, Landmark",
                    value: address,
                    required: true,
                  },
                  { label: "City", value: city, required: true },
                  { label: "Pincode", value: pincode, required: true },
                  { label: "District", value: district, required: true },
                  { label: "State", value: state, required: true },
                  { label: "Latitude", value: latitude, required: true },
                  { label: "Longitude", value: longitude, required: true },
                  { label: "Country", value: country, required: true },

                  ...(isPaid
                    ? [
                        {
                          label: "Energy Tariff",
                          value: !!ownerCostPerkWh ? `₹${ownerCostPerkWh}` : "",
                          required: true,
                          isValid: isPriceValid(ownerCostPerkWh),
                        },
                        {
                          label: "Cost Per Unit (kWh)",
                          value: !!costPerkWh ? `₹${costPerkWh}` : "",
                          required: true,
                          isValid:
                            isPriceValid(costPerkWh) &&
                            parseFloat(costPerkWh) >
                              parseFloat(ownerCostPerkWh),
                        },
                        {
                          label: "Platform Fee",
                          value: !!platformFee ? `₹${platformFee}` : "",
                          required: true,
                          isValid: isPriceValid(platformFee),
                        },
                        {
                          label: "Platform Fee Discount",
                          value: !!platformFeeDiscount
                            ? `${platformFeeDiscount}%`
                            : "",
                          required: true,
                          isValid:
                            parseFloat(platformFeeDiscount) >= 0 &&
                            parseFloat(platformFeeDiscount) <= 100,
                        },
                        ...(settlementCycle
                          ? [
                              {
                                label: "Settlement Frequency",
                                value: settlementCycle,
                                required: true,
                              },
                            ]
                          : []),
                        {
                          label: "Payment Method",
                          value:
                            paymentMethodsData?.data?.constructor === Array &&
                            paymentMethodsData.data.length > 0 &&
                            paymentMethodId &&
                            paymentMethodsData.data.find(
                              (el: any) => el._id === paymentMethodId,
                            )
                              ? paymentMethodsData.data.find(
                                  (el: any) => el._id === paymentMethodId,
                                ).bankDetails.bankName +
                                " (•••• " +
                                paymentMethodsData.data
                                  .find((el: any) => el._id === paymentMethodId)
                                  .bankDetails.bankAccount.slice(-4) +
                                ")"
                              : "",
                          required: true,
                        },
                      ]
                    : []),

                  { header: "Availability", onEdit: () => setStep(1) },
                  { label: "Sunday", value: returnTime(0) },
                  { label: "Monday", value: returnTime(1) },
                  { label: "Tuesday", value: returnTime(2) },
                  { label: "Wednesday", value: returnTime(3) },
                  { label: "Thursday", value: returnTime(4) },
                  { label: "Friday", value: returnTime(5) },
                  { label: "Saturday", value: returnTime(6) },
                  { header: "Specification", onEdit: () => setStep(2) },
                  ...(chargerProtocol !== "OCPP"
                    ? [
                        {
                          label: "Over Voltage",
                          value:
                            overVoltage > 209 && overVoltage < 301
                              ? `${overVoltage} V`
                              : "",
                          required: true,
                        },
                        {
                          label: "Over Voltage Time",
                          value:
                            overVoltageTime > 0 && overVoltageTime <= 255
                              ? `${overVoltageTime} seconds`
                              : "",
                          required: true,
                        },
                        {
                          label: "Under Voltage",
                          value:
                            underVoltage > 139 && underVoltage < 221
                              ? `${underVoltage} V`
                              : "",
                          required: true,
                        },
                        {
                          label: "Under Voltage Time",
                          value:
                            underVoltageTime > 0 && underVoltageTime <= 255
                              ? `${underVoltageTime} seconds`
                              : "",
                          required: true,
                        },
                        {
                          label: "Over Current",
                          value:
                            overCurrent > 4 && overCurrent < 18
                              ? `${overCurrent} AMP`
                              : "",
                          required: true,
                        },
                        {
                          label: "Over Current Time",
                          value:
                            overCurrentTime > 0 && overCurrentTime <= 255
                              ? `${overCurrentTime} seconds`
                              : "",
                          required: true,
                        },
                        {
                          label: "Over Temperature",
                          value:
                            overTemperature > 49 && overTemperature < 91
                              ? `${overTemperature} °C`
                              : "",
                          required: true,
                        },
                        {
                          label: "Over Temperature Time",
                          value:
                            overTemperatureTime > 0 &&
                            overTemperatureTime <= 255
                              ? `${overTemperatureTime} seconds`
                              : "",
                          required: true,
                        },
                        {
                          label: "Under Temperature",
                          value:
                            underTemperature !== "" &&
                            Boolean(
                              Number(underTemperature) > -31 &&
                                Number(underTemperature) < 51
                            )
                              ? `${underTemperature} °C`
                              : "",
                          required: true,
                        },
                        {
                          label: "Under Temperature Time",
                          value:
                            underTemperatureTime > 0 &&
                            underTemperatureTime <= 255
                              ? `${underTemperatureTime} seconds`
                              : "",
                          required: true,
                        },
                        {
                          label: "Auto Cutoff",
                          value:
                            Number(autoCutOff) > 0 && Number(autoCutOff) < 5.1
                              ? `${Number(autoCutOff) * 100} AMP`
                              : "",
                          required: true,
                        },
                        {
                          label: "Auto Cutoff Time",
                          value:
                            autoCutOffTime > 0 && autoCutOffTime <= 255
                              ? `${autoCutOffTime} seconds`
                              : "",
                          required: true,
                        },
                        {
                          label: "Current Factor",
                          value:
                            Number(currentFactor) > 0 &&
                            Number(currentFactor) < 2.1
                              ? `${currentFactor}`
                              : "",
                          required: true,
                        },
                        {
                          label: "Voltage Factor",
                          value:
                            Number(voltageFactor) > 0 &&
                            Number(voltageFactor) < 2.1
                              ? `${voltageFactor}`
                              : "",
                          required: true,
                        },
                        {
                          label: "Active Power Factor",
                          value:
                            Number(activePowerFactor) > 0 &&
                            Number(activePowerFactor) < 2.1
                              ? `${activePowerFactor}`
                              : "",
                          required: true,
                        },
                        {
                          label: "Energy Factor",
                          value:
                            Number(energyFactor) > 0 &&
                            Number(energyFactor) < 2.1
                              ? `${energyFactor}`
                              : "",
                          required: true,
                        },
                        { label: "Power Output", value: `${powerOutput} A` },
                      ]
                    : [
                        {
                          label: "Auto Cutoff",
                          value:
                            autoCutOffTime > 0 && autoCutOffTime <= 255
                              ? `${autoCutOffTime} seconds`
                              : "",
                          required: true,
                        },
                        { label: "Power Output", value: `${powerOutput} A` },
                      ]),
                ].map(
                  (
                    { header, onEdit, label, value, required, isValid },
                    i,
                    arr,
                  ) => {
                    const isFirst = arr[i - 1]?.header;
                    const isLast = !arr[i + 1] || arr[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <span>{header}</span>
                            <IconButton
                              sx={{ ml: 1.5 }}
                              children={<EditOutlined />}
                              color="primary"
                              size="small"
                              onClick={onEdit}
                            />
                          </td>
                        ) : (
                          <>
                            <td>{label}</td>
                            <td className="bold">
                              {required &&
                              ["", null, undefined].includes(value) ? (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    sx={{ mr: 1 }}
                                  />
                                  Required
                                </Box>
                              ) : (
                                <Box display="flex" alignItems="center">
                                  {value === "" ? "-" : value}
                                  {isValid === false && (
                                    <Box
                                      ml={2}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <WarningAmber
                                        fontSize="small"
                                        color="warning"
                                        sx={{ mr: 0.5 }}
                                      />
                                      Invalid
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant="contained"
          color={
            isComplete(step) || step === steps.length - 1
              ? "primary"
              : "inherit"
          }
          disableElevation
          disabled={step === steps.length - 1 && isDisabled()}
        >
          {step === steps.length - 1
            ? "Save"
            : isComplete(step)
              ? "Next"
              : "Skip"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PriceInput = ({
  objKey,
  label,
  input,
  setInput,
  isPriceValid,
  currency,
  min = 1,
  max = 30,
}: any) => {
  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <TextField
        fullWidth
        size="small"
        type="number"
        placeholder={label}
        value={input[objKey]}
        onChange={(e: any) => {
          if (e.target.value === "" || /^[0-9.]{0,5}$/.test(e.target.value))
            setInput({
              ...input,
              [objKey]: e.target.value,
            });
        }}
        error={!!input[objKey] && !isPriceValid(input[objKey])}
        helperText={
          currency === "INR"
            ? `Enter a value between ${min} and ${max}`
            : undefined
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ ml: 1 }}>
              {getSymbolFromCurrency(currency) || "?"}
            </InputAdornment>
          ),
        }}
      />
      <IconButton
        size="small"
        color="primary"
        sx={{ ml: 0.5, mt: 0.75 }}
        onClick={() =>
          setInput({
            ...input,
            [objKey]:
              parseFloat(input[objKey]) >= min + 1
                ? String(parseFloat(input[objKey]) - 1)
                : String(min),
          })
        }
      >
        <Remove />
      </IconButton>
      <IconButton
        size="small"
        color="primary"
        sx={{ ml: 0.5, mt: 0.75 }}
        onClick={() =>
          setInput({
            ...input,
            [objKey]:
              input[objKey] === ""
                ? "1"
                : parseFloat(input[objKey]) <= max - 1
                  ? String(parseFloat(input[objKey]) + 1)
                  : String(max),
          })
        }
      >
        <Add />
      </IconButton>
    </Box>
  );
};

export default EditChargerDialog;
